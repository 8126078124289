import React, { useEffect, useRef, useState } from "react";
import videojs from 'video.js';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import { FaPlay, FaPause } from 'react-icons/fa';
import { GrForwardTen, GrBackTen } from "react-icons/gr";
import AdInformationCard from "./AdInformationCard";
import { useTranslation } from "react-i18next";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { episodeDetailsAction, getWishListAction } from "../../Redux/Actions/apiActions";
import { wishListAction } from "../../Pages/CommonMethods/CommonMethods";
import { useMyContext } from "../Layout/CommonProvider";
export const SeriesJs = ({ episodeDetailsData, options, onReady, settings, wishListCheckValue }) => {

  const [currentEpisode, setCurrentEpisode] = useState(episodeDetailsData);
  const getLastViewedVideo = episodeDetailsData?.Episode_details?.continue_watching_exists?.current_time;
  const { t } = useTranslation();
  const { lang, series_slug } = useParams();
  const navigate = useNavigate();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [buttonFillPercentage, setButtonFillPercentage] = useState(0);
  const access_token = localStorage.getItem("access_token");
  const { navigateTranslateChecker } = useMyContext()
  const headers = {
    "Content-Type": "application/json", Authorization: "Bearer " + access_token,
    Accept: "application/json"
  };
  const [showButton, setShowButton] = useState({ skipButton: false, recapButton: false, cardButton: false, relatedVideo: false });
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const TimeConverter = (data) => {
    if (!data || typeof data !== "string" || !data.includes(":")) {
      return 0;
    }
    const [hours, minutes, seconds] = data.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      console.error("Invalid time components:", { hours, minutes, seconds });
      return 0;
    }
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };
  const handleRecap = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime - TimeConverter(episodeDetailsData?.Episode_details?.recap_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, recapButton: false, }));
  };
  const handleSkip = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime + TimeConverter(episodeDetailsData?.Episode_details?.skip_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, skipButton: false, }));
  }
  const handleFullscreen = () => {
    const player = playerRef.current;
    if (player) {
      if (player.isFullscreen()) {
        player.exitFullscreen();
      } else {
        player.requestFullscreen();
      }
    }
  };
  const dispatch = useDispatch();
  const episodeApi = async (episodeSlug) => {
    if (settings && Object.keys(settings).length > 0) {
      try {
        let actionResult;
        if (!user_role && settings.access_free == 1) {
          actionResult = await dispatch(episodeDetailsAction(null, 9, "web", series_slug, episodeSlug, headers));
        } else if (user_role) {
          actionResult = await dispatch(episodeDetailsAction(user_id, user_role, "web", series_slug, episodeSlug, headers));
        }
        //console.log(actionResult)
        return { status: true, data: actionResult }; // Return the action result as API response
      } catch (error) {
        console.error('Error fetching episode data:', error);
        return { status: false, error: error.message }; // Return error information if there's an error
      }
    } else {
      return { status: false, error: 'Invalid settings' }; // Return error if settings are invalid
    }
  };
  
  useEffect(() => {
    if (videoRef.current && !playerRef.current) {
      const player = videojs(videoRef.current, options, () => {
        playerRef.current = player;
        episodeDetailsData?.Episode_details?.Subtitle?.forEach((subtitle) => {
          player.addRemoteTextTrack({
            kind: 'captions',
            label: subtitle?.subtitle_language,
            srclang: subtitle?.shortcode,
            src: subtitle?.subtitle_url,
          });
        });
        player.loadingSpinner.removeClass('vjs-loading-spinner');
        player.loadingSpinner.addClass('vjs-loading-spinner-myvideo');
        const toggleButtons = () => {
          const currentTime = player.currentTime();
          const startShowTimeSkip = TimeConverter(episodeDetailsData?.Episode_details?.skip_start_time);
          const endShowTimeSkip = TimeConverter(episodeDetailsData?.Episode_details?.skip_end_time);
          const startShowTimeRecap = TimeConverter(episodeDetailsData?.Episode_details?.recap_start_time);
          const endShowTimeRecap = TimeConverter(episodeDetailsData?.Episode_details?.recap_end_time);
          const startShowTimeCard = TimeConverter(episodeDetailsData?.Episode_details?.url_linktym);
          const endShowTimeCard = TimeConverter(episodeDetailsData?.Episode_details?.urlEnd_linksec);
          setShowButton((prevState) => ({
            ...prevState, skipButton: currentTime >= startShowTimeSkip && currentTime <= endShowTimeSkip,
            recapButton: currentTime >= startShowTimeRecap && currentTime <= endShowTimeRecap,
            cardButton: currentTime >= startShowTimeCard && currentTime <= endShowTimeCard,
            relatedVideo: false,
          }));
        };
        onReady && onReady(player);
        player.on('fullscreenchange', () => setIsFullscreen(player.isFullscreen()));
        player.on('play', () => setIsPlaying(true));
        player.on('pause', () => setIsPlaying(false));
        player.on('userinactive', () => {
          const playPauseButton = document.querySelector('.play-skip-button-center');
          if (playPauseButton) {
            playPauseButton.style.display = 'none';
          }
        });
        player.on('useractive', () => {
          const playPauseButton = document.querySelector('.play-skip-button-center');
          if (playPauseButton) {
            playPauseButton.style.display = 'flex';
          }
        });
        player.on('timeupdate', toggleButtons);
        player.on('ended', () => {
          setShowButton((prevState) => ({ ...prevState, relatedVideo: true }));
        });
        player.on('timeupdate', () => {
          setCurrentTime(player.currentTime());
          setPlayedSeconds(player.currentTime());
          const current = player.currentTime();
          if (duration - current <= 4) {
            setShowNextButton(true);
            setButtonFillPercentage(((- (duration - current)) / 5) * 100);
          } else {
            setShowNextButton(false);
          }
        });
        player.on('loadedmetadata', () => {
          setDuration(player.duration());
          if (getLastViewedVideo) {
            player.currentTime(getLastViewedVideo);
          }
          const updateProgressControl = () => {
            const duration = player.duration();
            const watchedPercentage = (getLastViewedVideo / duration) * 100;
            const playProgress = player.controlBar.progressControl.el().querySelector('.vjs-play-progress');
            const sliderBar = player.controlBar.progressControl.el().querySelector('.vjs-slider-bar');
            if (playProgress && sliderBar) {
              playProgress.style.width = `${watchedPercentage}%`;
              sliderBar.style.width = `${watchedPercentage}%`;
            }
          };
          updateProgressControl();
        });
        
        return () => {
         if (player) {
            player.dispose(); 
          }
        };
      });

      const advertisement = episodeDetailsData?.Episode_details?.get_advertisement;
      const {
        pre_advertisement: CheckPreAds,
        post_advertisement: CheckPostAds,
        mid_advertisement: midrollAds,
        mid_advertisement_sequence_time: midrollInterval,
        advertisement_plays_24hrs: adPlays24hrs,
      } = advertisement;

      const loadGoogleIMASDK = () => {
        if (!window.google || !window.google.ima) {
          const script = document.createElement("script");
          script.src = "https://imasdk.googleapis.com/js/sdkloader/ima3.js";
          script.async = true;
          script.onload = () => {
            console.log("IMA SDK Loaded");
            initializeIMAPlugin();
          };
          script.onerror = () => console.error("Failed to load IMA SDK");
          document.body.appendChild(script);
        } else {
          console.log("IMA SDK already loaded");
          initializeIMAPlugin();
        }
      };

      const initializeIMAPlugin = () => {
        try{
          if (player && typeof player.ima === 'function') {
            player.ima({
              adTagUrl: '',
              showControlsForAds: true,
              debug: false,
              adsRenderingSettings: {
                loadVideoTimeout: 15000,
              }
            });
            player.ima.initializeAdDisplayContainer();
            initializeAds(CheckPreAds, CheckPostAds, midrollAds, midrollInterval);
          }
        } catch (error) {
          console.error("Error initializing IMA plugin", error);
        }
      };

      
      if( (CheckPreAds != null || CheckPostAds != null || midrollAds?.length > 0) && adPlays24hrs == 1 ) {
        loadGoogleIMASDK();
      }

    } else {
      return null;
    }
  }, [currentEpisode, episodeDetailsData, duration]);

  const initializeAds = async ( CheckPreAds, CheckPostAds, midrollAds, midrollInterval ) => {
    const player = videojs(videoRef.current);

    // Ads Marker
    player.on("loadedmetadata", function () {
      const markers = [];
      const total = player.duration();

      if (total != 'Infinity') {
        if (CheckPreAds) markers.push({ time: 0 });

        if (midrollInterval && midrollAds?.length > 0 ) {
          console.log("total", total);
          for (let time = midrollInterval; time < total; time += midrollInterval) {
            markers.push({ time });
          }
        }

        if (CheckPostAds) markers.push({ time: total });


        const markerSpace = player.controlBar.progressControl.el();
        markers.forEach((marker) => {
          const time = marker.time;
          let left = time == 0 ? (marker.time / total) * 100 + 0.5 + "%" : time == total ? (marker.time / total) * 100 - 1 + "%" : (marker.time / total) * 100 + "%"
          const el = document.createElement("div");
          el.className = "vjs-marker";
          el.style.left = left;
          el.dataset.time = time;
          el.addEventListener("click", () => {
            player.currentTime(time);
          });
          markerSpace.appendChild(el);
        });
      }
    });

    var prerollTriggered = false;
    let lastMidrollTime = 0;

    if (CheckPreAds && player.currentTime() == 0) {
      player.pause();
      player.ima.changeAdTag(CheckPreAds);
    }

    player.on("timeupdate", () => {
      const currentTime = player.currentTime();
      const duration = player.duration();

      if (midrollAds && currentTime - lastMidrollTime >= midrollInterval - 1) {
        player.pause();
        lastMidrollTime = currentTime;
        player.ima.changeAdTag(midrollAds[0]);
        player.ima.requestAds();
      }

      if (CheckPostAds && currentTime >= duration - 2) {
        console.log("Postroll triggered");
        player.pause();
        player.ima.changeAdTag(CheckPostAds);
        player.ima.requestAds();
      }
    });

    player.on("aderror", function (event) {
      console.error("Ad error: ", event);
      player.play();
    });

    player.on("adend", function () {
      prerollTriggered = false;
      player.play();
    });
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  };

  const newCurrentTime = Math.floor(currentTime);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locationData, setLocationData] = useState({ city: '', region: '', country: '', latitude: '', longitude: '', ip: '', });
  // Calculate watch percentage
  useEffect(() => {
    if (duration > 0) {
      const percentage = (playedSeconds / duration) * 100;
      setWatchPercentage(percentage);
    }
  }, [playedSeconds, duration]);
  const calculatePercentage = watchPercentage.toFixed(2);
  const userRole = user_role ? user_role : '9'; // 9 for guest users
  const SaveLastViewedVideo = async () => {
    try {
      const data = {
        Login_user_type: userRole,
        Login_device_type: 'web',
        Login_user_id: user_id,
        source_type_id: 4,  //For Series
        source_id: episodeDetailsData?.Episode_details?.id,
        source_type: 4,
        current_time: newCurrentTime,
        watch_percentage: `${calculatePercentage}%`,
        skip_time: '0',
        country_name: locationData?.country,
        region_name: locationData?.region,
        city_name: locationData?.city
      }
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/continue-watching-recent-view-store-update`, data);
      setData(response.data);
      // console.log(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const backButton = () => {
    SaveLastViewedVideo();
    const { access_free } = settings;
    const isGuest = !localStorage.getItem("role");
    const { access, slug } = episodeDetailsData?.Episode_details || {};
    if (window.history.state && window.history.state.idx > 0) {
      window.history.back();
    } else {
      (isGuest && access === "guest" && access_free === 0) ? navigate(`/`) :
        navigate(navigateTranslateChecker(`/shows/${series_slug}`));
    }
  };
  // useEffect(() => {
  //   const player = videojs(videoRef.current, options, () => {
  //     if (player) {
  //       // player.hlsQualitySelector({ displayCurrentQuality: true });
  //       const timeout = setTimeout(() => setShowButton((prevState) => ({ ...prevState, skipButton: false, })), 10000);
  //       return () => clearTimeout(timeout);
  //     }
  //   })
  // }, []);

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player) {
      if (player.paused()) {
        setTimeout(() => {
          player.play().catch((error) => {
            console.error('Error playing video:', error);
          });
        }, 100); // 100ms delay
      } else {
        player.pause();
      }
    }
  };
  const skipForward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + duration);
  };
  const skipBackward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() - duration);
  };

  const [showSlider, setShowSlider] = useState(false);
  const toggleSlider = () => {
    setShowSlider(prevShowSlider => !prevShowSlider);
    setShowButton({ relatedVideo: false })
    const swiperContainer = document.querySelector('.swiper-container');
    if (swiperContainer) {
      swiperContainer.style.display = showSlider ? 'block' : 'none';
    }
  };
  useEffect(() => {

    if (videoRef.current && !playerRef.current) {
      const player = videojs(videoRef.current, {}, function onPlayerReady() {
        console.log('Player is ready!');
      });
      playerRef.current = player;
      player.on('ended', () => {
        console.log(!wishListCheckValue, '!wishlist');
        if (!wishListCheckValue) {
          wishListAction(null, null, null, episodeDetailsData?.Episode_details?.id, 4);
          dispatch(getWishListAction());
        }
      });

      return () => {
        if (playerRef.current) {
          // console.log(playerRef.current)
          // playerRef.current.dispose();
          // playerRef.current = null;
        }
      };
    }
  }, [wishListCheckValue]); 


  return (
    <>
      <div data-vjs-player className="position-relative" style={{ "overflow": "hidden" }}>
        <div className="d-flex align-items-center position-relative px-3 py-2 gap-3">
          <AiOutlineClose style={{ "fontSize": "35px", "fontWeight": "bold", "fill": "white", "cursor": "pointer" }} onClick={backButton} />
          <h3 className="theme-text-color">{t(episodeDetailsData?.Episode_details?.title)}</h3>
        </div>
        <div className="position-absolute col-6 play-skip-button-center align-items-center justify-content-between">
          <button className=" bg-transparent" onClick={() => skipBackward(10)}>
            <GrBackTen style={{ fontSize: "38px" }} />
          </button>
          <button className=" bg-transparent" onClick={togglePlayPause}>
            {isPlaying ? <FaPause style={{ fontSize: "35px" }} /> : <FaPlay style={{ fontSize: "35px" }} />}
          </button>
          <button className=" bg-transparent" onClick={() => skipForward(10)}>
            <GrForwardTen style={{ fontSize: "38px" }} />
          </button>
        </div>
        <button className="custom-fullscreen-button bg-transparent" onClick={handleFullscreen}>
          {isFullscreen ? <BsFullscreenExit style={{ fontSize: "32px" }} /> : <BsArrowsFullscreen style={{ fontSize: "32px" }} />}
        </button>
        <div className="d-flex align-items-center position-absolute skipRecapWrapper gap-3">
          {showButton?.skipButton && (<button onClick={handleSkip} className="skipBtn bg-transparent  rounded-2 border border-light px-5 py-3" > <span className="theme-text-color skipText">Skip Intro </span> </button>)}
          {/* {showButton?.recapButton && (<button onClick={handleRecap} className="skipBtn bg-transparent  rounded-2 border border-light px-3 py-2" > <VscDebugRestart className="theme-text-color" style={{ fontSize: "30px" }} /> </button>)} */}
        </div>
        {showButton?.cardButton && <AdInformationCard title={episodeDetailsData?.Episode_details?.title} link={episodeDetailsData?.Episode_details?.url_link} />}
        <video data-vjs-player ref={videoRef} className="vjs-big-play-centered vjs-theme-city my-video-dimensions video-js vjs-play-control customVideoPlayer" />
        {/* {showNextButton && (<button className="next-episode-button" style={buttonStyle} onClick={handleVideoEnded}> Next Episode </button>)} */}
        {/* {showButton?.relatedVideo && <RelatedVideosSwiper relatedVideo={episodeDetailsData?.Episode_details?.title} onClick={toggleSlider} />} */}
      </div>
    </>
  );
};
export default SeriesJs;