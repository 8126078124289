import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import "./Register_Payments.css";
import { toast } from 'react-toastify';
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import usePpvGlobalImageConfig from "../Api/Rentppv_global_change";
import { useMyContext } from "../Layout/CommonProvider";
import iyzico from '../../assets/images/iyzico.svg'
import stripe from '../../assets/images/stripe.svg'
import razorpay from '../../assets/images/razorpay.svg'
import paypal from '../../assets/images/paypal.svg'
import paystack from '../../assets/images/paystack.svg'

function Register_Payments({ profileDetails, settingsall }) {
  const { navigateTranslateChecker } = useMyContext()
  const { t } = useTranslation();
  const { lang } = useParams()
  const access_token = localStorage.getItem("access_token");
  const user_id_signup = localStorage.getItem("signup_user_id");
  const user_id = localStorage.getItem("user_id");

  const signup_user_id = user_id_signup || user_id || '';

  const navigate = useNavigate();

  const handleSkipClick = () => {
    if (access_token) {
      window.location.href = "/";
      localStorage.clear();
    } else {
      window.location.href = "/login";
      localStorage.clear();
    }
  };

  const username = profileDetails?.Profile_details?.username || "";
  const lastName = profileDetails?.Profile_details?.last_name || "";

  // Concatenate and capitalize the first letter of the combined string
  const capitalizedUsername = `${(username + " " + lastName).trim()}`.replace(/^./, str => str.toUpperCase());

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [stripeids, setStripeids] = useState([]);

  useEffect(() => {
    fetchOptions();
    fetchPaymentGateways();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Subscription-Plans`,
        { headers: headers }
      );
      const StripeSubscriptionPlans = response?.data?.Stripe_Subscription_Plans;
      setStripeids(StripeSubscriptionPlans);

      // console.log(StripeSubscriptionPlans);
    } catch (error) {
      console.error(error);
    }
  };

  const [paymentGateways, setPaymentGateways] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

  const [datatranscation, setDatatranscation] = useState({});
  const fetchPaymentGateways = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`);
      const paymentGateways = response?.data?.Payment_Gateways || [];
      setPaymentGateways(paymentGateways);

      // Set default active gateway
      if (paymentGateways.length > 0) {
        setActiveGateway(paymentGateways[0]?.Payment_Gateway_name);
        setSelectedOption(paymentGateways[0]?.Payment_Gateway_name);
      }
    } catch (error) {
      console.error("Error fetching payment gateways:", error);
    }
    try {
      // setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_Baseurl}/Front-End/user-details`, {
        method: "POST",
        headers: headers,
      });
      const result = await response.json();
      setDatatranscation(result.transaction_details?.current_Subscription);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching transaction data", error);
      // setLoading(false);
    }
  };


  //stripe
  const checkoutstripepayment = (plan_id) => {
    const baseUrl = process.env.REACT_APP_Baseurl;
    const successUrl = "/login";
    const failureUrl = "/login";
    const currentPageUrl = process.env.REACT_APP_API_PATHss;
    const success_url = `${currentPageUrl}/login`;
    const failure_url = `${currentPageUrl}/login`;

    const requestData = {
      user_id: signup_user_id,
      plan_id,
      quantity: 1,
      currentPageUrl,
      success_url,
      failure_url,
    };
    fetch(`${baseUrl}/Front-End/Stripe-Payment-Link`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response?.ok) return response?.json();
        return response?.json().then((json) => Promise.reject(json));
      })
      .then((response) => {
        if (response?.status == true) {
          const paymentLinkURL = response?.Payment_Link_URL;
          const newTab = window.open(paymentLinkURL, "_self");
          if (newTab) {
            newTab.focus();
            newTab.onbeforeunload = () => {
              // Redirect to the success page when the popup is closed
              // window.location.href = successUrl;
            };
            localStorage.clear();
            toast.success(t("Payment Proceed"));
          } else {
            console.error("Popup blocked by the browser");
            toast.error(t("Popup blocked by the browser"));
          }
        } else {
          console.error("Payment session status is not true");
          toast.error(t("Payment session status is not true"));
          // window.location.href = failureUrl; // Redirect to the failure URL
        }
      })
      .catch((e) => {
        console.error(e.message); // Log the error message
        toast.error(t("Payment session status is not true"));
        // window.location.href = failureUrl; // Redirect to the failure URL
      });
  };

  // razorpay

  const initPayment = (data, tag) => {
    const options = {
      key: tag?.name,
      amount: data?.amount * 100, // Amount should be in paisa
      currency: data?.currency,
      name: tag?.name,
      description: tag?.name,
      image: tag?.img,
      order_id: data?.id,
      handler: async (response) => {
        try {
          const verifyUrl = "http://localhost:7000/api/payment/verify";
          const paymentData = {
            response,
            tag,
          };
          const { data } = await axios.post(verifyUrl, paymentData);
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePaymentrazorpay = async (tag) => {
    try {
      const orderUrl = "http://localhost:7000/api/payment/orderss";
      const { data } = await axios.post(orderUrl, {
        quantity: 1,
        user_id: tag?.id,
        amount: tag?.price,
        plan_id: tag?.id,
      });
      console.log(data);
      initPayment(data?.data, tag);
    } catch (error) {
      console.log(error);
    }
  };




  // iyzico payment subcription payment 
  // iyzico payment subcription payment 
  // iyzico payment subcription payment 

  const [error, setError] = useState(null);
  const { currency, currencySymbol: fetchedCurrencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
  const [currencySymbol, setCurrencySymbol] = useState("");

  console.log(currencySymbol, "currencySymbolcurrencySymbol")
  useEffect(() => {
    if (fetchedCurrencySymbol) {
      setCurrencySymbol(fetchedCurrencySymbol);
      console.log(fetchedCurrencySymbol, "fetchedCurrencySymbol")
    }
  }, [fetchedCurrencySymbol]);

  const [convertedPrice, setConvertedPrice] = useState([]);
  const [formDataiyzico, setFormDataiyzico] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);


  useEffect(() => {
    const fetchConversionRate = async () => {
      if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
        const data = response.data;
        const conversionRate = data.rates[userCurrency];

        if (conversionRate) {
          const convertedPrices = formDataiyzico.map(item => ({
            ...item,
            convertedPrice: item.price * conversionRate
          }));
          setConvertedPrice(convertedPrices);
        } else {
          console.error('Conversion rate not found for currency:', userCurrency);
        }
      } catch (error) {
        console.error('Error fetching conversion rate:', error);
      }
    };

    fetchConversionRate();
  }, [userCurrency, currency?.code, currency?.enable_multi_currency, formDataiyzico]);

  const ppv_priceppv_price = convertedPrice?.map(item => item.convertedPrice);

  useEffect(() => {
    // Fetch the payment gateways and store them in state
    const fetchPaymentGatewaysiyzico = async () => {
      if (!profileDetails || !locationResponselocationResponse) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}/Front-End/Subscription-Plans`,
          { headers: headers }
        );

        const paymentGateways = response?.data?.Iyzico_Subscription_Plans;

        if (!Array.isArray(paymentGateways)) {
          throw new Error('Payment gateways data is not an array');
        }

        const getCurrentDateTime = () => {
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0');
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        // const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failer";
        const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
        const successpagesuccesspage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
        // const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
        const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/payment-callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.country;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        // const successpagesuccesspage = `${process.env.REACT_APP_API_PATHss}`;
        // const buyerDetails = {
        //   name: "Pradeepraj",
        //   id: "45",
        //   gsmNumber: "+905051234567",
        //   email: "pradeep@webnexs.in",
        //   identityNumber: "+905051234567",
        //   city: "Istanbul",
        //   country: "Turkey",
        //   ip: "85.34.78.112"
        // };

        const formattedGateways = paymentGateways?.map(gateway => ({
          locale: locale,
          conversationId: gateway.plan_id,
          price: gateway.price,
          paidPrice: gateway.price,
          currency: currencyfinal,
          basketId: basketId,
          paymentGroup: 'PRODUCT',
          callbackUrl: callbackUrl,
          successpage: successpagesuccesspage,
          failerpage: failerpage,
          regionname: country,
          countryname: country,
          Login_user_id: user_ids,
          Login_device_type: "web",
          cityname: city,
          enabledInstallments: [2, 3, 6, 9, 12],
          billing_interval: gateway.billing_interval,
          plans_name: gateway.plans_name,
          billing_type: gateway.billing_type,
          days: gateway.days,
          video_quality: gateway.video_quality,
          plan_content: gateway.plan_content,
          resolution: gateway.resolution,
          ios_plan_price: gateway.ios_plan_price,
          ios_product_id: gateway.ios_product_id,
          plan_trail_days: gateway.plan_trail_days,
          created_at: gateway.created_at,
          created_at: gateway.created_at,
          buyer: {
            id: user_ids,
            name: usernames,
            surname: last_name,
            gsmNumber: payment_mobile_number,
            email: emailemail,
            identityNumber: gsmNumber,
            lastLoginDate: currentDateTime,
            registrationDate: currentDateTime,
            registrationAddress: country,
            ip: ip,
            city: city,
            country: country,
            zipCode: zipCode
          },
          shippingAddress: {
            contactName: usernames,
            city: city,
            country: country,
            district: city,
            address: country,
            zipCode: zipCode
          },
          billingAddress: {
            contactName: usernames,
            city: city,
            country: country,
            address: country,
            zipCode: zipCode
          },
          basketItems: [
            {
              id: basketId,
              name: gateway.plans_name,
              category1: 'MEMBERSHIP',
              itemType: 'VIRTUAL',
              price: gateway.price
            }
          ]
        }));

        setFormDataiyzico(formattedGateways);
        setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
      } catch (error) {
        console.error('Error fetching payment gateways:', error);
      }
    };

    fetchPaymentGatewaysiyzico();
    // retrievePaymentDetails();
  }, [profileDetails, locationResponselocationResponse]);

  // Handle product selection and subscription
  const handleProductClick = async (product) => {
    setSelectedProduct(product);

    const request = {
      locale: product.locale,
      conversationId: product.conversationId,
      callbackUrl: product.callbackUrl,
      pricingPlanReferenceCode: product.conversationId, // Adjust based on your data structure
      subscriptionInitialStatus: "ACTIVE",
      price: product.price,
      paidPrice: product.paidPrice,
      currency: product.currency,
      basketId: product.basketId,
      paymentGroup: 'PRODUCT',
      callbackUrl: product.callbackUrl,
      successpage: product.successpage,
      failerpage: product.failerpage,
      regionname: product.regionname,
      countryname: product.countryname,
      Login_user_id: product.Login_user_id,
      Login_device_type: "web",
      cityname: product.cityname,
      customer: {
        name: product.buyer.name,
        surname: product.buyer.surname,
        identityNumber: product.buyer.gsmNumber,
        email: product.buyer.email,
        gsmNumber: product.buyer.gsmNumber,
        billingAddress: {
          contactName: product.billingAddress.contactName,
          city: product.billingAddress.city,
          district: product.billingAddress.district,
          country: product.billingAddress.country,
          address: product.billingAddress.address,
          zipCode: product.billingAddress.zipCode
        },
        shippingAddress: {
          contactName: product.shippingAddress.contactName,
          city: product.shippingAddress.city,
          district: product.shippingAddress.district,
          country: product.shippingAddress.country,
          address: product.shippingAddress.address,
          zipCode: product.shippingAddress.zipCode
        }
      },
      pricingPlanPrice: product.price // Ensure this is set correctly
    };
    // setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/create-subscription`, request);

      if (response.data.status === "success") {
        const scriptContent = response.data.checkoutFormContent;

        // Inject and initialize Iyzico script
        const scripts = scriptContent.match(/<script[^>]*>([\s\S]*?)<\/script>/g) || [];
        scripts.forEach((script) => {
          const scriptElement = document.createElement('script');
          scriptElement.textContent = script.replace(/<\/?script[^>]*>/g, '');
          document.body.appendChild(scriptElement);
        });

        if (window.iyziInit && typeof window.iyziInit.createTag === 'function') {
          window.iyziInit.createTag();
        } else if (window.iyziUcsInit && typeof window.iyziUcsInit.createTag === 'function') {
          window.iyziUcsInit.createTag();
        } else if (window.iyziSubscriptionInit && typeof window.iyziSubscriptionInit.createTag === 'function') {
          window.iyziSubscriptionInit.createTag();
        } else {
          throw new Error('Iyzico script initialization failed.');
        }
        localStorage.clear();
      }
      else if (response.data.status === "failure") {
        toast.error(`${response.data.errorMessage}`)
      }
    } catch (err) {
      console.error('Payment Error:', err);
    }
  };

  const initializeCheckoutcancel = async (product) => {

    setSelectedProduct(product);
    try {
      const response = await axios.post('http://localhost:7004/cancel-subscription', {
        subscriptionReferenceCode: 'bf5c5980-4eea-417d-a4df-fe8ae15976eb', // Add reference code from your subscription
        conversationId: product.conversationId // Conversation ID
      });
      console.log(response);

      if (response.data.status === 'success') {
        // Handle success (e.g., redirect or notify user)
      } else if (response.data.status === 'failure') {
        toast.error(`${response.data.errorMessage}`);
      }
    } catch (error) {
      console.error('Error initializing cancel:', error);
    }
  };


  // iyzico payment subcription payment 
  // iyzico payment subcription payment 

  useEffect(() => {
    if (paymentGateways?.length > 0) {
      setActiveGateway(paymentGateways[0]?.Payment_Gateway_name);
    }
  }, [paymentGateways]);

  const handleGatewayClick = (gatewayName) => {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      // Redirect to login page if no access_token
      navigate(navigateTranslateChecker(`/login`));
    } else {
      // Otherwise, set the clicked gateway as active
      setActiveGateway(gatewayName);
    }
  };

  const staticContent = {
    Stripe: (
      <>
        <div>
          <ul className="nav nav-pills mb-3 payment_stripe_css">
            {stripeids?.map((tab, index) => (
              <>
                <li
                  className="nav-item align-items-center ms-2 paymentstripe_with_css theme-border-button-payment"
                  role="presentation"
                  key={index}
                >
                  <div className="row col-lg-12">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 power_play_header_css mt-2">
                      <h4 className="power_play theme-text-color" htmlFor={tab?.id + "-tab"}>
                        {tab?.plans_name}
                      </h4>
                      <p className="power_play_price mt-3 theme-text-color">
                        {tab?.price} {settingsall?.currency_settings?.symbol} / {tab?.billing_interval}
                      </p>
                      <p className="power_play_price_save m-0 theme-text-color">
                        <div dangerouslySetInnerHTML={{ __html: tab && tab?.plan_content }} className='my-2'></div>
                      </p>
                    </div>

                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 padding_right_col">
                      <div className="row col-lg-12 mt-2">
                        <div className="row register_animate_text">
                          <div className="col-5 col-sm5 col-md-5 col-lg-5"><p>Days</p></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.days}</div>
                        </div>
                        <div className=" row register_animate_text mt-2">
                          <div className="col-5 col-sm5 col-md-5 col-lg-5"><p>Video Quality</p></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.video_quality}</div>
                        </div>
                        <div className=" row register_animate_text mt-2">
                          <div className="col-5 col-sm5 col-md-5 col-lg-5"><p>Plan Trail Days</p></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.plan_trail_days}</div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="text-end mb-2">
                    <button className="p-2 start_payment_css btn theme-button-bg-color accessButton  bgButton w-50"
                      onClick={() => checkoutstripepayment(tab?.plan_id)}>{t("Start")}</button>
                  </div>

                  {/* <div className="row justify-content-evenly free_video_plans_button mb-2">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-end">
                    <div className="mt-2">
                      <button
                        className="btn theme-button-bg-color accessButton  bgButton"
                        onClick={() => checkoutstripepayment(tab?.plan_id)}
                      >
                        Start
                      </button>
                      </div>
                    </div>
                  </div> */}
                </li>
              </>

            ))}
          </ul>
          {/* <ul className="nav nav-pills mb-3 payment_stripe_css">
            {stripeids?.map((tab, index) => (
              <>
                <li
                  className="nav-item align-items-center ms-2 paymentstripe_with_css "
                  role="presentation"
                  key={index}
                >
                  <div className="row col-lg-12">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 power_play_header_css mt-4">
                      <label className="power_play theme-text-color" htmlFor={tab?.id + "-tab"}>
                        {tab?.plans_name}
                      </label>
                      <p className="power_play_price mt-4 theme-text-color">
                        $ {tab?.price} {tab?.billing_interval}
                      </p>
                      <p className="power_play_price_save m-0 theme-text-color">
                        <div dangerouslySetInnerHTML={{ __html: tab && tab?.plan_content }} className='my-2'></div>
                      </p>
                    </div>


                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 padding_right_col">
                      <div className="row col-lg-12  mt-4">
                        <div className="justify-content-between row register_animate_text">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9"><h6>Days</h6></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.days}</div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9"><h6>Video Quality</h6></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.video_quality}</div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9"><h6>Plan Trail Days</h6></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.plan_trail_days}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-evenly free_video_plans_button">
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                      <div className="mt-2">
                        <button
                          className="btn theme-button-bg-color accessButton  bgButton"
                          onClick={() => checkoutstripepayment(tab?.plan_id)}
                        >
                          Start
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </>

            ))}
          </ul> */}
        </div>
      </>
    ),
    PayPal: (
      <>
        <div>
          <ul
            className="nav nav-pills mb-3 payment_stripe_css"
            id="pills-tab"
            role="tablist"
          >
            {stripeids?.map((tab, index) => (
              <li
                className="nav-item align-items-center ms-2 paymentpaypal_with_css"
                role="presentation"
                key={index}
              >
                <div className="row col-lg-12">
                  <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
                    <label className="power_play theme-text-color" htmlFor={tab?.id + "-tab"}>
                      {tab?.title}
                    </label>

                    <p
                      className="power_play_OTTs mt-2 theme-text-color"
                      htmlFor={tab?.id + "-tab"}
                    >
                      {tab?.title}
                    </p>
                    <p className="power_play_price mt-4 theme-text-color">
                      $ {tab?.price} per yr
                    </p>
                    <p className="power_play_price_save m-0 theme-text-color">
                      Save upto <span>$ {tab?.price}</span>
                    </p>
                  </div>
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="row col-lg-12">
                      {tab?.video_categories?.map((category) => (
                        <div
                          className="col-6 col-sm-6 col-md-6 col-lg-6"
                          key={category?.id}
                        >
                          <div className="free_video_plans_image">
                            <img
                              src={category?.src}
                              className="w-100 h-100"
                              alt={category?.title}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-evenly free_video_plans_button">
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="mt-2">
                      <button
                        className="btn btn-primary bgButton"
                        onClick={() =>
                          checkoutstripepayment(Number(tab?.price))
                        }
                      >
                        {t("Start")}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    ),
    Razorpay: (
      <>
        <div>
          {" "}
          <ul
            className="nav nav-pills mb-3 payment_stripe_css"
            id="pills-tab"
            role="tablist"
          >
            {stripeids?.map((tab, index) => (
              <li
                className="nav-item align-items-center ms-2 paymentrazor_with_css"
                role="presentation"
                key={index}
              >
                <div className="row col-lg-12">
                  <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
                    <label className="power_play  theme-text-color" htmlFor={tab?.id + "-tab"}>
                      {tab?.title}
                    </label>

                    <p
                      className="power_play_OTTs mt-2 theme-text-color"
                      htmlFor={tab?.id + "-tab"}
                    >
                      {tab?.title}
                    </p>
                    <p className="power_play_price mt-4 theme-text-color">
                      $ {tab?.price} per yr
                    </p>
                    <p className="power_play_price_save m-0 theme-text-color">
                      Save upto <span>$ {tab?.price}</span>
                    </p>
                  </div>

                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="row col-lg-12">
                      {tab?.video_categories?.map((category) => (
                        <div
                          className="col-6 col-sm-6 col-md-6 col-lg-6"
                          key={category?.id}
                        >
                          <div className="free_video_plans_image">
                            <img
                              src={category?.src}
                              className="w-100 h-100"
                              alt={category?.title}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-evenly free_video_plans_button">
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="mt-2">
                      <button
                        className="btn theme-button-bg-color accessButton  bgButton"
                        onClick={() => handlePaymentrazorpay(tab)}
                      >
                        {t("Start")}
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    ),
    Iyzico: (
      <>
        <div>


          <ul className="nav nav-pills mb-3 payment_stripe_css">
            {formDataiyzico?.map((product, index) => {
              // Calculate the conversion rate
              // const price = ppv_priceppv_price[index] || product.price;
              // const price = (ppv_priceppv_price[index] || product.price).toFixed(2);
              const price = parseFloat(ppv_priceppv_price[index] || product.price).toFixed(2);
              return (
                <>
                  <li
                    className="nav-item align-items-center ms-2 paymentstripe_with_css theme-border-button-payment"
                    role="presentation"
                    key={index}
                  >
                    <div className="row col-lg-12">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 power_play_header_css mt-2">
                        <h4 className="power_play theme-text-color" htmlFor={product?.id + "-tab"}>
                          {t(product?.plans_name)}
                        </h4>
                        <p className="power_play_price mt-3 theme-text-color">
                          {currency?.code === null || currency?.code === ""
                            ? `${price} ${currencySymbol}`
                            : `${price} ${currencySymbol}`
                          } {"/"} {product?.billing_interval}
                        </p>

                        {/* <p className="power_play_price mt-3 theme-text-color">
                          {tab?.price} {settingsall?.currency_settings?.symbol} / {tab?.billing_interval}
                        </p> */}
                        <p className="power_play_price_save m-0 theme-text-color">
                          <div dangerouslySetInnerHTML={{ __html: product && product?.plan_content }} className='my-2'></div>
                        </p>
                      </div>

                      {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 padding_right_col">
                        <div className="row col-lg-12 mt-2">
                          <div className="row register_animate_text">
                            <div className="col-5 col-sm5 col-md-5 col-lg-5"><p>Days</p></div>
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">{product?.days}</div>
                          </div>
                          <div className=" row register_animate_text mt-2">
                            <div className="col-5 col-sm5 col-md-5 col-lg-5"><p>Video Quality</p></div>
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">{product?.video_quality}</div>
                          </div>
                          <div className=" row register_animate_text mt-2">
                            <div className="col-5 col-sm5 col-md-5 col-lg-5"><p>Plan Trail Days</p></div>
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3">{product?.plan_trail_days}</div>
                          </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="text-end mb-2">

                      {datatranscation?.plans_id === product?.conversationId ? (
                        <div className="mt-2">
                          {/* <button className="btn theme-button-bg-color accessButton bgButton" disabled>
                            Current Plan
                          </button> */}
                          <button className="p-2 start_payment_css btn theme-button-bg-color accessButton  bgButton w-50"
                            disabled>{t("Current Plan")}</button>
                        </div>
                      ) : (
                        <div className="mt-2">
                          <button className="p-2 start_payment_css btn theme-button-bg-color accessButton  bgButton w-50"
                            onClick={() => handleProductClick(product)}>{t("Start")}</button>
                        </div>
                      )}

                      {/* <button className="p-2 start_payment_css btn theme-button-bg-color accessButton  bgButton w-50"
                        onClick={() => checkoutstripepayment(product?.plan_id)}>Start</button> */}
                    </div>

                    {/* <div className="row justify-content-evenly free_video_plans_button mb-2">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-end">
                    <div className="mt-2">
                      <button
                        className="btn theme-button-bg-color accessButton  bgButton"
                        onClick={() => checkoutstripepayment(tab?.plan_id)}
                      >
                        Start
                      </button>
                      </div>
                    </div>
                  </div> */}
                  </li>
                </>
              );
            })}
          </ul>


          {/* <ul
            className="nav nav-pills mb-3 payment_stripe_css"
            id="pills-tab"
            role="tablist"
          >
            {formDataiyzico?.map((product, index) => {
             
              const price = parseFloat(ppv_priceppv_price[index] || product.price).toFixed(2);


              return (
                <li
                  className="nav-item align-items-center ms-2 paymentiyzico_with_css"
                  role="presentation"
                  key={index}
                >
                  <div className="row col-lg-12">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 power_play_header_css mt-4">
                      <label className="power_play theme-text-color">
                        {product?.plans_name}
                      </label>

                      <p
                        className="power_play_OTTs mt-2 theme-text-color"
                        htmlFor={`${product?.id}-tab`}
                      >
                        {product?.buyer?.name}
                      </p>
                      <p className="power_play_price mt-4 theme-text-color">
                        {currency?.code === null || currency?.code === ""
                          ? `${currencySymbol} ${price}`
                          : `${currencySymbol} ${price}`
                        } {product?.billing_interval}
                      </p>
                      <p
                        className="power_play_price_save m-0 theme-text-color mt-4"
                        dangerouslySetInnerHTML={{ __html: product?.plan_content }}
                      />
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 padding_right_col">
                      <div className="row col-lg-12 mt-4">
                        <div className="justify-content-between row register_animate_text">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                            <h6>Days</h6>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            {product?.days}
                          </div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                            <h6>Video Quality</h6>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            {product?.video_quality}
                          </div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                            <h6>Plan Trail Days</h6>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            {product?.plan_trail_days}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-evenly free_video_plans_button">
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5">

                      {datatranscation?.plans_id === product?.conversationId ? (
                        <div className="mt-2">
                          <button className="btn theme-button-bg-color accessButton bgButton" disabled>
                            Current Plan
                          </button>
                        </div>
                      ) : (
                        <div className="mt-2">
                          <button
                            className="btn theme-button-bg-color accessButton bgButton"
                            onClick={() => handleProductClick(product)}
                          >
                            Start
                          </button>
                        </div>
                      )}

                    </div>
                  </div>
                </li>
              );
            })}
          </ul> */}
        </div>

      </>
    ),
  };

  // Array of payment gateway icons (assumes they are in order)
  const gatewayIcons = {
    Stripe: stripe,
    PayPal: paypal,
    Razorpay: razorpay,
    Paystack: paystack,
    Iyzico: iyzico,
  };

  return (
    <section className="register_Payment  paddingTop">
      <div className="container register_content">
        <div className="row paymenttababoveheading">
          <div className="col-8">

            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link href="/">{t("Home")}</Link></li>
                <li className="ms-2"><Link href={lang + "/plan"}>{">"}</Link></li>
                <li className="breadcrumb-item active ms-2" aria-current="page"><Link href={lang + "/plan"}> {t("Subcription Plan")}</Link></li>
              </ol>
            </nav>

          </div>
          <div className="col-12 col-sm-8 col-md-8 col-lg-8 mt-2">
            <h2 className="theme-text-color">
              {t("Membership and Prices")}
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="subcription_live_css plan-lineargrade my-4"></div>
          </div>
        </div>

        <div className="container">
          <p className="signupnamecss theme-text-color"> {t("Hello")}, {capitalizedUsername}</p>
        </div>
        <div className="container pt-3 "></div>

        {/* <div className="row">
          <div className="col-6 col-md-6 col-lg-8 ">
            <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
              {paymentGateways?.map((gateway, index) => (
                <li
                  className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                  role="presentation"
                  key={index}
                >
                  <input
                    type="radio"
                    className="nav-link"
                    id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                    name="tab-radio"
                    value={gateway?.Payment_Gateway_name}
                    checked={activeGateway == gateway?.Payment_Gateway_name}

                    onClick={() => handleGatewayClick(gateway?.Payment_Gateway_name)}
                  />
                  <label
                    className="ms-2 theme-text-color"
                    htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                  >
                    {gateway?.Payment_Gateway_name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div> */}


        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-12">
            <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
              {paymentGateways?.map((gateway, index) => {
                const iconUrl = gatewayIcons[gateway?.Payment_Gateway_name] || ""; // Match gateway name to icon

                return (
                  <li
                    key={index}
                    className={`option-card ms-1 ${selectedOption === gateway?.Payment_Gateway_name ? "active-option" : ""}`}
                    onClick={() => {
                      setSelectedOption(gateway?.Payment_Gateway_name);
                      handleGatewayClick(gateway?.Payment_Gateway_name);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {iconUrl && <img src={iconUrl} alt={gateway?.Payment_Gateway_name} style={{ width: "90px" }} />}
                      <div className="ms-3">
                        <h6 className=""> {t(gateway?.Payment_Gateway_name)}</h6>
                      </div>
                    </div>
                    <input
                      type="radio"
                      id={`radio-${gateway?.Payment_Gateway_name}`}
                      name="subscription"
                      value={gateway?.Payment_Gateway_name}
                      checked={selectedOption === gateway?.Payment_Gateway_name}
                      onChange={() => setSelectedOption(gateway?.Payment_Gateway_name)}
                      hidden
                    />
                    <label htmlFor={`radio-${gateway?.Payment_Gateway_name}`} className="radio-button"></label>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>


        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 offset-md-0">
            <div className="tab-content text-start" id="pills-tabContent">
              {paymentGateways?.map((gateway, index) => (
                <div
                  className={`tab-pane theme-text-color ${selectedOption === gateway?.Payment_Gateway_name ? "active" : ""}`}
                  id={`pills-${gateway?.Payment_Gateway_name}`}
                  role="tabpanel"
                  key={index}
                >
                  <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                </div>
              ))}
            </div>
          </div>
        </div>



        {/* <div className="row">
          <div className="col-12 col-md-12 col-lg-12 offset-md-0">
            <div className="tab-content text-start" id="pills-tabContent">
              {paymentGateways?.map((gateway, index) => (
                <div
                  className={`tab-pane theme-text-color ${selectedOption === gateway?.Payment_Gateway_name ? "active-option" : ""}`}
                  id={`pills-${gateway?.Payment_Gateway_name}`}
                  role="tabpanel"
                  key={index}
                >
                  <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                </div>
              ))}
            </div>
          </div>
        </div> */}

        <div className="text-center skiptohome">
          <div className="py-3">
            <label className=" theme-text-color">{t("OR")}</label>
          </div>

          <div className="py-3">
            <Link onClick={handleSkipClick}>
              <button className="btn w-25 bgButton">{t("Skip")}</button>
            </Link>
          </div>
        </div>
      </div>
    </section>

  );
}


const mapStateToProps = state => ({
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
  settingsall: state.get_setting_Reducer.settingsall,
});
export default connect(mapStateToProps)(Register_Payments)

// export default Register_Payments;
