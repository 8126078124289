import React, { useEffect, useState } from 'react'
import MessageBox from '../MessageBox'
import { IoClose } from 'react-icons/io5'
import axios from 'axios';
import { toast } from 'react-toastify';

const TermAndConditionModal = ({ modelClose, deleteClose }) => {
    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true)
    const type = "terms-and-conditions"

    const pageAction = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Pages`);
            const ListRes = response?.data?.Page.filter((item) => item.slug === type);
            setLoading(false)
            setPage(ListRes?.[0]);
            console.log(ListRes)
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || "An error occurred");
        }
    };

    useEffect(() => {
        pageAction();
    }, []);

    return (
        loading ? <p>loading...</p> : <div class={`modal  termModal packageModel fade ${modelClose && 'd-block show'}`} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content  theme-bg-color ">
                    <div className="modal-header theme-border-bottom-secondary">
                        <MessageBox text={page?.title} classname="modal-title theme-text-color admin-modal-title fw-bold" />
                        <button type="button" className='bg-transparent theme-text-color' onClick={() => deleteClose()} ><IoClose fill='currentColor' style={{ width: "25px", height: "25px" }} /></button>
                    </div>
                    <div className="modal-body ">
                        <div dangerouslySetInnerHTML={{ __html: page?.body }} />
                    </div>
                    <div className="modal-footer  border-0">
                        <button type="button" className="bg-transparent theme-text-color sliderBtnText px-2" onClick={() => deleteClose()} ><MessageBox text="Close" /> </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermAndConditionModal