import React, { useEffect, useRef, useState } from "react";
import videojs from 'video.js';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import { FaPlay, FaPause } from 'react-icons/fa';
import { GrForwardTen, GrBackTen } from "react-icons/gr";
import { VscDebugRestart } from "react-icons/vsc";
import AdInformationCard from "./AdInformationCard";
import { useTranslation } from "react-i18next";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import RelatedVideosSwiper from "./ReletedVideosSwiper";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { wishListAction } from "../../Pages/CommonMethods/CommonMethods";
import 'videojs-hls-quality-selector';
import { useMyContext } from "../Layout/CommonProvider";


function convertTimeToSeconds(time) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
export const TrailerJS = ({ videoDetailsData, options, onReady, settings, imaOptions, audioTracks, wishListCheckValue }) => {
  let freeDurationInSeconds = null;
  if (videoDetailsData?.free_duration_status == 1) {
    freeDurationInSeconds = convertTimeToSeconds(videoDetailsData?.free_duration_time);
  }
  const [hasReachedTimeLimit, setHasReachedTimeLimit] = useState(false);
  const [showPurchasePage, setShowPurchasePage] = useState(false);
  const getLastViewedVideo = videoDetailsData?.continue_watching_exists?.current_time;
  const { t } = useTranslation();
  const { navigateTranslateChecker } = useMyContext();
  const { lang, slug } = useParams();
  const navigate = useNavigate();
  const [videoEnded, setVideoEnded] = useState(false);
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState({ skipButton: false, recapButton: false, cardButton: false, relatedVideo: false });
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [bufferedTime, setBufferedTime] = useState(0);
  const [totalSkipForward, setTotalSkipForward] = useState(0);
  const [totalSkipBackward, setTotalSkipBackward] = useState(0);

  const TimeConverter = (data) => {
    if (!data || typeof data !== "string" || !data.includes(":")) {
      return 0;
    }
    const [hours, minutes, seconds] = data.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      console.error("Invalid time components:", { hours, minutes, seconds });
      return 0;
    }
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  const handleRecap = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime - TimeConverter(videoDetailsData?.recap_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, recapButton: false, }));
  };
  const handleSkip = () => {
    const currentTime = playerRef.current.currentTime();
    const backTime = currentTime + TimeConverter(videoDetailsData?.skip_start_session);
    playerRef.current.currentTime(backTime);
    setShowButton((prevState) => ({ ...prevState, skipButton: false, }));
  }
  const handleFullscreen = () => {
    const player = playerRef.current;
    if (player) {
      if (player.isFullscreen()) {
        player.exitFullscreen();
      } else {
        player.requestFullscreen();
      }
    }
  };
  // Function to handle video end
  const handleVideoEnded = () => {
    setVideoEnded(true); // Mark video as ended
    setShowButton({
      skipButton: false,
      recapButton: false,
      cardButton: false,
      relatedVideo: true,
    });
  };


  useEffect(() => {
    if (videoRef.current && videoDetailsData) {
      const player = videojs(videoRef.current, options, () => {
        playerRef.current = player;

        // Add subtitles
        videoDetailsData?.Subtitle?.forEach((subtitle) => {
          player.addRemoteTextTrack({
            kind: 'captions',
            label: subtitle?.subtitle_language,
            srclang: subtitle?.shortcode,
            src: subtitle?.subtitle_url,
          });
        });

        player.loadingSpinner.removeClass('vjs-loading-spinner');
        player.loadingSpinner.addClass('vjs-loading-spinner-myvideo');

        // Prevent right-click context menu
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
        videoRef.current.addEventListener('contextmenu', handleContextMenu);

        // Toggle button visibility based on current time
        const toggleButtons = () => {
          const currentTime = player.currentTime();
          const startShowTimeSkip = TimeConverter(videoDetailsData?.skip_start_time);
          const endShowTimeSkip = TimeConverter(videoDetailsData?.skip_end_time);
          const startShowTimeRecap = TimeConverter(videoDetailsData?.recap_start_time);
          const endShowTimeRecap = TimeConverter(videoDetailsData?.recap_end_time);
          const startShowTimeCard = TimeConverter(videoDetailsData?.url_linktym);
          const endShowTimeCard = TimeConverter(videoDetailsData?.urlEnd_linksec);

          setShowButton((prevState) => ({
            ...prevState,
            skipButton: currentTime >= startShowTimeSkip && currentTime <= endShowTimeSkip,
            recapButton: currentTime >= startShowTimeRecap && currentTime <= endShowTimeRecap,
            cardButton: currentTime >= startShowTimeCard && currentTime <= endShowTimeCard,
            relatedVideo: false,
          }));
        };

        // Calculate buffered time
        const calculateBufferedTime = () => {
          const buffered = player.buffered();
          let totalBufferedTime = 0;
          for (let i = 0; i < buffered.length; i++) {
            totalBufferedTime += buffered.end(i) - buffered.start(i);
          }
          // console.log(`Total buffered time: ${totalBufferedTime} seconds`);
          // You can now set the buffered time to state if you need to use it later
          setBufferedTime(totalBufferedTime); // If you want to store the buffered time in state
        };

        // Add Video.js event listeners
        player.on('fullscreenchange', () => setIsFullscreen(player.isFullscreen()));
        player.on('play', () => {
          setIsPlaying(true);
          setVideoEnded(false);
        });

        player.on('pause', () => setIsPlaying(false));
        player.on('ended', handleVideoEnded);

        // Custom play/pause button visibility
        player.on('userinactive', () => {
          const playPauseButton = document.querySelector('.play-skip-button-center');
          if (playPauseButton) {
            playPauseButton.style.display = 'none';
          }
        });
        player.on('useractive', () => {
          const playPauseButton = document.querySelector('.play-skip-button-center');
          if (playPauseButton) {
            playPauseButton.style.display = 'flex';
          }
        });


        // Calculate buffered time during video loading
        player.on('progress', calculateBufferedTime);

        // Button visibility updates based on time
        player.on('timeupdate', toggleButtons);


        // When video metadata is loaded
        player.on('loadedmetadata', () => {
          setDuration(player.duration());
          if (videoDetailsData?.users_free_duration_status == false) {
            if (getLastViewedVideo) {
              player.currentTime(getLastViewedVideo);
            }
            const updateProgressControl = () => {
              const duration = player.duration();
              const watchedPercentage = (getLastViewedVideo / duration) * 100;
              const playProgress = player.controlBar.progressControl.el().querySelector('.vjs-play-progress');
              const sliderBar = player.controlBar.progressControl.el().querySelector('.vjs-slider-bar');
              if (playProgress && sliderBar) {
                playProgress.style.width = `${watchedPercentage}%`;
                sliderBar.style.width = `${watchedPercentage}%`;
              }
            };
            updateProgressControl();
          }
        });

        player.on('ended', () => {
          setShowButton((prevState) => ({ ...prevState, relatedVideo: true }));

        });

        return () => {
          videoRef.current.removeEventListener('contextmenu', handleContextMenu);
          player.dispose();
        };
      });
      player.on('ready', () => {
        const liveControl = document.querySelector('.vjs-live-control');
        if (liveControl != undefined && liveControl != null) {
          liveControl.style.display = 'none';
        }
      });
    }
  }, [videoDetailsData, hasReachedTimeLimit]);


  //FreeDuration
  useEffect(() => {
    if (videoDetailsData?.users_free_duration_status === true &&
      videoDetailsData?.users_video_visibility_status == true && hasReachedTimeLimit) {
      setShowPurchasePage(true)
      navigate(navigateTranslateChecker(`/purchase/${slug}`));
    }
  }, [videoDetailsData, hasReachedTimeLimit, lang, slug]);

  const newCurrentTime = Math.floor(currentTime);
  const location = useLocation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [locationData, setLocationData] = useState({ city: '', region: '', country: '', latitude: '', longitude: '', ip: '', });
  useEffect(() => {
    if (duration > 0) {
      const percentage = (playedSeconds / duration) * 100;
      setWatchPercentage(percentage);
    }
  }, [playedSeconds, duration]);
  const calculatePercentage = watchPercentage.toFixed(2);
  const userRole = user_role ? user_role : '9';



  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    const player = videojs(videoRef.current, options, () => {
      if (player) {

        if (player.hlsQualitySelector) {
          player.hlsQualitySelector({ displayCurrentQuality: true });
        } else {
          console.error('hlsQualitySelector plugin is not available.');
        }
      }
    })
  }, []);

  const togglePlayPause = () => {
    const player = playerRef.current;
    if (player) {
      if (player.paused()) {
        setTimeout(() => {
          player.play().catch((error) => {
            console.error('Error playing video:', error);
          });
        }, 100);
      } else {
        player.pause();
      }
    }
  };



  const closeButton = () => {
    try {
      const { access_free } = settings;
      const isGuest = !localStorage.getItem("role");
      const { access, slug } = videoDetailsData || {};
      if (window.history.state && window.history.state.idx > 0) {
        window.history.back();
      } else {
        if (isGuest && access == "guest" && access_free == 0) {
          navigate('/');
        } else {
          navigate(navigateTranslateChecker(`/videos/${slug}`));
        }
      }
    } catch (error) {
      console.error('Error in backButton:', error);
    }
  };

  const skipForward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() + duration);
    setTotalSkipForward(prev => prev + duration); // Update total skip forward time
  };

  const skipBackward = (duration) => {
    const player = playerRef.current;
    player.currentTime(player.currentTime() - duration);
    setTotalSkipBackward(prev => prev + duration); // Update total skip backward time
  };

  const [showSlider, setShowSlider] = useState(false);
  const toggleSlider = () => {
    setShowSlider(prevShowSlider => !prevShowSlider);
    setShowButton({
      relatedVideo: false
    })
    const swiperContainer = document.querySelector('.swiper-container');
    if (swiperContainer) {
      swiperContainer.style.display = showSlider ? 'block' : 'none';
    }
  };
  const handleReload = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setVideoEnded(false); // Reset the state when video reloads
      setIsPlaying(true); // Ensure the play button works correctly
    }
  };

  return (
    <div>
      <div data-vjs-player className="position-relative" style={{ "overflow": "hidden" }}>
        <div className="d-flex align-items-center position-relative px-3 py-2 gap-3">
          <AiOutlineClose style={{ "fontSize": "35px", "fontWeight": "bold", "fill": "white", "cursor": "pointer" }} onClick={closeButton} />
          <h3 className="theme-text-color">{t(videoDetailsData?.title)}</h3>
        </div>
        {!videoEnded && (
          <>
            <div className="position-absolute col-6 play-skip-button-center align-items-center justify-content-between">
              <button className=" bg-transparent" onClick={() => skipBackward(10)}>
                <GrBackTen style={{ fontSize: "38px" }} />
              </button>
              <button className=" bg-transparent" onClick={togglePlayPause}>
                {isPlaying ? <FaPause style={{ fontSize: "35px" }} /> : <FaPlay style={{ fontSize: "35px" }} />}
              </button>
              <button className=" bg-transparent" onClick={() => skipForward(10)}>
                <GrForwardTen style={{ fontSize: "38px" }} />
              </button>
            </div>
            <button className="custom-fullscreen-button bg-transparent" onClick={handleFullscreen}>
              {isFullscreen ? <BsFullscreenExit style={{ fontSize: '28px' }} /> : <BsArrowsFullscreen style={{ fontSize: '28px' }} />}
            </button>
          </>
        )}
        {/* {videoEnded && (
          <button className="custom-reload-button bg-transparent" onClick={handleReload}>
            <span className="reload-text">Reload</span>
          </button>
        )} */}
        <button className="custom-fullscreen-button bg-transparent" onClick={handleFullscreen}>
          {isFullscreen ? <BsFullscreenExit style={{ fontSize: "28px" }} /> : <BsArrowsFullscreen style={{ fontSize: "28px" }} />}
        </button>
        <div className="d-flex align-items-center position-absolute skipRecapWrapper gap-3">
          {showButton?.skipButton && (<button onClick={handleSkip} className="skipBtn bg-transparent rounded-2 border border-light px-5 py-3" > <span className="theme-text-color skipText">Skip Intro </span> </button>)}
          {/* {showButton?.recapButton && (<button onClick={handleRecap} className="skipBtn bg-transparent  rounded-2 border border-light px-3 py-2" > <VscDebugRestart className="theme-text-color" style={{ fontSize: "30px" }} /> </button>)} */}
        </div>
        {showButton?.cardButton && <AdInformationCard title={videoDetailsData?.title} link={videoDetailsData?.url_link} />}
        {/* {showButton?.relatedVideo && <EndCard relatedVideo={videoDetailsData?.title} />} */}
        <video data-vjs-player ref={videoRef} className="vjs-big-play-centered vjs-theme-city my-video-dimensions video-js vjs-play-control customVideoPlayer" />
        {/* {showButton?.relatedVideo && <RelatedVideosSwiper relatedVideo={videoDetailsData?.title} onClick={toggleSlider} />} */}
      </div>

    </div>
  );
};

export default TrailerJS;
