import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../Styles/Multiprofile.css";
import { useTranslation } from "react-i18next";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";
import MessageBox from "../MessageBox";
import { connect } from "react-redux";
import LazyLoadImage from "../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage";
import PageLoader from "../Loader/PageLoader";
import { IconMyPlus, IconUserEdit } from "../../assets/icons/MyIcons";
import { useMyContext } from "../Layout/CommonProvider";


const Multiprofile = ({ profileDetails, currentTheme }) => {
  const { navigateTranslateChecker } = useMyContext()
  const { t } = useTranslation();

  const userRoleLocal = getItem('role')
  const userIdLocal = getItem('user_id')
  const userAccessToken = getItemToken('access_token');

  const [SubcriberUser, setSubcriberUser] = useState({
    limit: 0,
    userDetails: {},
    multiProfile: {}
  });
  const [loading, setLoading] = useState(true)
  const [domReload, setDomReload] = useState(false)
  const navigate = useNavigate();


  const fetchProfileData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Multi-Profile/index`, { headers: getItemToken("access_token") });
      const result = response?.data
      if (result?.status) {
        setSubcriberUser((prev) => ({
          ...prev,
          limit: result?.Multi_User_Limit,
          userDetails: result?.subcriber_user,
          multiProfile: result?.Multi_users
        }))
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching data:", error);

    }
  };

  const chooseProfile = (data) => {
    localStorage.setItem("MultiProfile", true);
    localStorage.setItem("MultiProfileId", data);
    window.location.href = "/";
  }


  useEffect(() => {
    fetchProfileData();
  }, [domReload]);

  return (
    loading ? <PageLoader /> :
      <div className="min-vh-100  col-11 col-md-5 col-lg-8 col-xl-7 mx-auto py-5" >
        <h2 className="profile-chooser-title pb-5">{t("Who's Watching")}?</h2>
        <div className="d-flex align-items-center flex-wrap justify-content-center pb-4 gap-5" >
          <Link onClick={() => window.location.href = navigateTranslateChecker('/')}>
            <div className="profileChoose d-flex flex-column" >
              <LazyLoadImage src={profileDetails?.Profile_details?.user_avatar} alt="Avatar" imageSize="avatarProfile rounded-circle" classNameOptional="avatarProfile rounded-circle" />
              <MessageBox text={t(SubcriberUser?.userDetails?.name)} classname="theme-text-color mt-2 mb-0 text-center" />
            </div>
          </Link>

          {SubcriberUser?.multiProfile?.map((profile, index) => (
            <div className="profileChoose d-flex flex-column justify-content-center align-items-center"  >
              <div onClick={() => chooseProfile(profile?.id)}>
                <LazyLoadImage src={profile?.image_url} alt="Avatar" imageSize="avatarProfile rounded-circle" classNameOptional="avatarProfile rounded-circle " />
              </div>
              <div className="d-flex align-items-center justify-content-between gap-2 mt-2">
                <MessageBox text={t(profile?.user_name)} classname="theme-text-color mb-0 text-center text-break textProfileWidth" />
                {currentTheme == 1 && <Link to={navigateTranslateChecker(`/multi-profile/edit/${profile?.id}`)} className=""><IconUserEdit styled={{ width: "18px", height: "18px" }} /></Link>}
                {currentTheme == 2 && <Link to={navigateTranslateChecker(`/myprofile/multi-profile/edit/${profile?.id}`)} className=""><IconUserEdit styled={{ width: "18px", height: "18px" }} /></Link>}
              </div>
            </div>
          ))}

          {Number(SubcriberUser?.limit) >= Number(SubcriberUser?.multiProfile?.length) && <Link to={navigateTranslateChecker(`/${currentTheme == 1 ? "multi-profile/create" : currentTheme == 2 ? "myprofile/multi-profile/create" : ""}`)}>
            <div className="profileChoose d-flex flex-column" >
              <div className="avatarProfile add d-flex align-items-center justify-content-center theme-border-color border border-2 rounded-circle">
                <IconMyPlus styled={{ width: "40px", height: "40px" }} />
              </div>
              <MessageBox text={t('Add')} classname="theme-text-color mt-2 mb-0 text-center" />
            </div>
          </Link>}
        </div>
      </div>
  );
};

const mapStateToProps = state => ({
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
  profileDetails: state?.get_profileDetails_Reducer?.profileDetails,
  currentTheme: state.get_headerIndex_Reducer.currentTheme,
})
export default connect(mapStateToProps)(Multiprofile)