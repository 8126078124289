
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { videoDetailsAction } from '../../Redux/Actions/apiActions';
import { useNavigate, useParams } from 'react-router-dom';
import TrailerJS from './Trailer';
import PageLoader from '../Loader/PageLoader';

const TrailerPlayer = ({ videoDetailsData, isLoading, error, settings }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_role = localStorage.getItem("role");
  const playerType = videoDetailsData?.type;
  const embedSource = videoDetailsData?.embed_code;
  const { slug } = useParams();
  const videoJsOptions = {
    controlBar:
    {
      subtitlesButton: false,
      remainingTimeDisplay: true,
      volumePanel:
      {
        inline: false,
        vertical: true
      },
      pictureInPictureToggle: false
    },
    bigPlayButton: false,
    autoplay: true,
    controls: true,
    responsive: true,
    breakpoints: [{
      tiny: 300,
      xsmall: 400,
      small: 500,
      medium: 600,
      large: 700,
      xlarge: 800,
      huge: 900
    }],
    textTrackSettings: false,
    fluid: true,
    sources: [{
      src: videoDetailsData?.trailer_videos_url,
      type: videoDetailsData?.video_player_type
    }],
    // playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
    html5: {
      nativeTextTracks: false
    }
  };

  useEffect(() => {
    dispatch(videoDetailsAction(slug, settings))
  }, [settings, user_role])
  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("waiting", () => {
      // videojs.log("player is waiting");
    });
    player.on("dispose", () => {
      // videojs.log("player will dispose");
    });
  };
  return (
    <>
      {
        isLoading
          ? <div className='d-flex justify-content-center align-items-center paddingTop'>
            <PageLoader />
          </div>
          : playerType === "embed_url|url"
            ? <iframe src={embedSource} allowfullscreen width="1360" height="594" title="Trailer"></iframe>
            : <>
              <TrailerJS options={videoJsOptions} onReady={handlePlayerReady} videoDetailsData={videoDetailsData}
                settings={settings} />
            </>
      }</>
  )
}
const mapStateToProps = state => ({
  videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
  isLoading: state.get_videoDetails_Reducer.isLoading,
  settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(TrailerPlayer)
