
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { episodeDetailsAction, getWishListAction } from '../../Redux/Actions/apiActions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SeriesJs from './SeriesJs';
import 'videojs-contrib-ads';
import 'videojs-ima';
import { useTranslation } from 'react-i18next';
import PageLoader from '../Loader/PageLoader';

const SeriesPlayer = ({ episodeDetailsData, isLoading, error, settings, getWishListData }) => {
    // console.log(episodeDetailsData?.Episode_details)
    const { series_slug, episode_slug } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const playerType = episodeDetailsData?.type;
    const embedSource = episodeDetailsData?.embed_code;
    const seriesJsOptions = {
        controlBar:
        {
            //captionsButton: true,
            subtitlesButton: false,
            remainingTimeDisplay: true,
            volumePanel:
            {
                inline: false,
                vertical: true
            },
            pictureInPictureToggle: true
        },
        bigPlayButton: false,
        autoplay: true,
        controls: true,
        responsive: true,
        breakpoints: [{
            tiny: 300,
            xsmall: 400,
            small: 500,
            medium: 600,
            large: 700,
            xlarge: 800,
            huge: 900
        }],
        textTrackSettings: false,
        fluid: true,
        sources: [{
            src: episodeDetailsData?.Episode_details?.episode_url,
            type: episodeDetailsData?.Episode_details?.episode_player_type
        }],
        playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
        html5: {
            nativeTextTracks: false
        }
    };

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(episodeDetailsAction(null, 9, "web", series_slug, episode_slug))
                : user_role && dispatch(episodeDetailsAction(user_id, user_role, "web", series_slug, episode_slug));
        }
    }, [settings, user_role])
    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on("waiting", () => {
        });
        player.on("dispose", () => {
        });
    };

    useEffect(() => {
        dispatch(getWishListAction())
    }, [])

    const wishListCheck = getWishListData?.Episodes?.some(item => item.id === episodeDetailsData?.Episode_details?.id);

    return (
        <>
            {isLoading
                ? <div className='d-flex justify-content-center align-items-center paddingTop'>
                    <PageLoader />
                </div>
                : episodeDetailsData?.Episode_details?.episode_url && episodeDetailsData?.Episode_details?.episode_player_type
                    ?
                    <SeriesJs options={seriesJsOptions} onReady={handlePlayerReady} episodeDetailsData={episodeDetailsData}
                        settings={settings} wishListCheckValue={wishListCheck} />


                    : <div className='d-flex justify-content-center align-items-center paddingTop'>
                        <h3>{t("Invalid Episode src and type")}</h3>
                    </div>
            }</>
    )
}
const mapStateToProps = state => ({
    episodeDetailsData: state.get_episodeDetails_Reducer.episodeDetailsData,
    isLoading: state.get_episodeDetails_Reducer.isLoading,
    settings: state.get_setting_Reducer.settings,
    getWishListData: state?.get_wishList_Reducer?.getWishListData
});
export default connect(mapStateToProps)(SeriesPlayer)
