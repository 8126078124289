import React from 'react'
import GoogleIcon from "../../assets/icons/google-icon.webp"
import FaceBookIcon from "../../assets/icons/facebook-icon.webp"
import LazyLoadImage from "../HomePage/HomeSwiperComponents/LazyLoadImage"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { MdClose } from "react-icons/md"
import { VscEyeClosed } from "react-icons/vsc"
import { RxEyeOpen } from "react-icons/rx"
import PhoneInput from "react-phone-input-2"
import { useMyContext } from "../../components/Layout/CommonProvider"
const SignUpThemeTwo = ({ themeMode, authAction, error, onchange, field, show, data, passAction, modalClose, signInModal, showTwo, passTwoAction, ipConfig, numberValue, checkChange, phoneChange, imageOnChange, handleState, handleCountry, handleCity, stateid, cityid, countryid, termModal }) => {
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    return (
        <div className={`w-100 ${data?.pop ? `${data.pop} h-100 active pt-5` : 'min-vh-100'}  themeTwoBannerWrapper `} >
            {!data?.headerDisable && <header className="theme-bg-color py-3 mb-3 ">
                <LazyLoadImage src={themeMode?.logoUrl} alt={"logo"} classNameOptional="rounded-3 mx-auto signin-logo-images" imageSize="signin-logo-images loaderMain object-fit-cover mx-auto mb-0" />
            </header>}
            <div className='col-11 col-md-8 col-lg-4 mx-auto theme-bg-color-secondary p-md-5 p-2 rounded-2 position-relative'>
                <p className='mb-md-5 mb-3 text-center d-block themeTwoHead theme-text-color-button'>Sign Up</p>
                {data?.headerDisable && <button className='bg-transparent p-0 theme-text-color position-absolute closeBtnModal' type='button' onClick={() => modalClose()}><MdClose className='playlistIcon' /></button>}
                <div className='d-flex align-items-center justify-content-center gap-5'>
                    <div className='d-flex align-items-center'>
                        <img src={GoogleIcon} className='ThemeTwoSignIcon me-1 d-block' />
                        <span>Google</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <img src={FaceBookIcon} className='ThemeTwoSignIcon me-1 d-block' />
                        <span>Facebook</span>
                    </div>
                </div>
                <form onSubmit={authAction} >
                    <span className="d-block my-md-4 my-2 text-center">OR</span>
                    <div className="position-relative pb-4 mb-3">
                        <input type="email" value={field?.email} placeholder={`${t('Enter your Email')} *`} name="email" autoComplete="off" onChange={onchange} className="d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" />
                        {error?.emailerr !== "" && (<span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.emailerr)}</span>)}
                    </div>
                    <div className="position-relative">
                        <PhoneInput country={ipConfig?.country_code?.toLowerCase()} value={numberValue} onChange={phoneChange} inputStyle={{ width: '100%' }} countryCodeEditable={false} disableCountryCode={false} disableSearchIcon enableSearch={true} placeholder="Enter your phone number" inputClass="d-block bg-transparent py-4 px-5 w-100 outline-0 rounded-0 mb-3 border border-1ownInputFlag theme-text-color" containerClass="mb-4" dropdownClass="theme-bg-color theme-text-color ownInputDropDown" buttonClass="bg-transparent border-0 ownButtonClass" />
                        {error?.numbererr !== "" && (
                            <div className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.numbererr)}</div>
                        )}
                    </div>
                    <div className="position-relative pb-4 mb-3">
                        <input type="text" placeholder="User Name" className=" d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" value={field?.username} name="username" onChange={onchange} />
                        {error?.usernameerr !== "" && (<span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.usernameerr)}</span>)}
                    </div>
                    <div className="position-relative pb-4 mb-3">
                        <input type="text" placeholder="Last Name" className=" d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" value={field?.lastname} name="lastname" onChange={onchange} />
                        {error?.lastnameerr !== "" && (<span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.lastnameerr)}</span>)}
                    </div>
                    <div className="position-relative pb-4 mb-3">
                        <input type={show ? 'password' : 'text'} name="password" value={field?.password} onChange={onchange} placeholder="Password" className="d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" />
                        {error?.passworderr !== "" && (<span className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.passworderr)}</span>)}
                        <button onClick={passAction} type="button" className="p-2  border-0 eyeButton bg-transparent">{show ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                    </div>
                    <div className="position-relative pb-4 mb-3">
                        <input type={showTwo ? 'password' : 'text'} name="confirmPassword" value={field?.confirmPassword} onChange={onchange} placeholder="Confirm Password" className="d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" />
                        <button onClick={passTwoAction} type="button" className="p-2  border-0 eyeButton bg-transparent">{showTwo ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                        {error?.confirmPassworderr !== "" && (<label className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.confirmPassworderr)}</label>)}
                    </div>
                    <div className='d-flex align-items-center mt-md-4 mt-2 mb-md-5 pb-4 mb-3 position-relative'>
                        <input type="checkbox" className='ThemeTwoCheckbox me-1 d-block bg-transparent' name="check" checked={field?.check} onChange={checkChange} />
                        <span>Accept <span className="theme-text-color-button" onClick={() => termModal()}>Terms & Conditions</span></span>
                        {error?.checkerr !== "" && (<label className="d-flex text-danger signin-error py-2 position-absolute">{t(error?.checkerr)}</label>)}
                    </div>
                    <button type="submit" className="d-block p-3 rounded-2 w-100">Sign Up</button>
                    <p className="signinLabelText mb-0 pt-3 theme-text-color">{t("Already have an account")} ? {data?.headerDisable ? <button type="button" className="border-0 bg-transparent theme-text-color" onClick={() => signInModal()}>{t("Sign In")}</button> : <Link to={navigateTranslateChecker(`/login`)} className="border-0 bg-transparent theme-text-color">{t("Sign In")}</Link>}</p>
                </form>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});

export default connect(mapStateToProps)(SignUpThemeTwo);
